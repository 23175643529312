@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Noto+Sans:wght@300;400;600&display=swap');

@font-face{
    font-family:"Snell Roundhand";
    src:url("../fonts/snell-roundhand/SnellBT-Regular.otf") format("opentype");
    font-weight: 400;
    font-display: swap;
}

@import 'variables.scss';
@import 'mixins.scss';
@import 'global.scss';
@import 'ui.scss';
@import 'layout.scss';
@import 'pages.scss';
@import 'animation.scss';
@import 'helpers';