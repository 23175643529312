._grid{
    width: calc(100% - 40px);
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
    ._column{
        width: 100%;
    }
    &._form-grid{
        max-width: 800px;
    }
    &._form-grid-small{
        max-width: 400px;
    }
    &._grid-2{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(50% - 20px);
            }
        }
    }
    &._grid-3{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(33.33% - 30px);
            }
        }
    }
    &._grid-4{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(25% - 30px);
            }
        }
    }
    @media(min-width: $tablet){
        width: calc(100% - 60px);
        column-gap: 30px;
    }
    @media(min-width: $laptop){
        width: calc(100% - 80px);
        column-gap: 40px;
    }
}

._header{
    position: relative;
    z-index: 3;
    ._inner{
        height: 80px;
        position: relative;
        display: flex;
        align-items: center;
        ._nav-list-left{
            display: none;
            position: absolute;
            right: calc(50% + 100px);
            top: 50%;
            transform: translateY(-50%);
        }
        ._nav-list-right{
            position: absolute;
            display: none;
            left: calc(50% + 100px);
            top: 50%;
            transform: translateY(-50%);
        }
        ._logo{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        ._call-to-action-link{
            display: none;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
    }
    &._header-initial{
        ._inner{
            height: 120px;
        }
        ._nav-list-left{
            right: calc(50% + 115px);
        }
        ._nav-list-right{
            left: calc(50% + 115px);
        }
    }
    &._header-secondary{
        background-color: $dark;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        @include transition;
        &._show{
            visibility: visible;
            opacity: 1;
        }
        ._logo{
            width: 120px;
        }
    }
    @media(min-width: $laptop){
        ._inner{
            ._nav-list-left{
                display: flex;
            }
            ._nav-list-right{
                display: flex;
            }
            ._call-to-action-link{
                display: block;
            }
            ._hamburger-icon{
                display: none;
            }
        }
    }
}

._nav-side{
    position: fixed;
    left: -100px;
    top: 0;
    height: 100%;
    min-height: 667px;
    width: 100%;
    background-color: $dark;
    visibility: hidden;
    opacity: 0;
    @include transition;
    z-index: 2;
    ._nav-side-list{
        padding-left: 0;
        height: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        ._nav-item{
            list-style: none;
            ._nav-link{
                display: block;
                padding: 13px 0;
                font-size: 20px;
                letter-spacing: 1px;
            }
        }
    }
    &._show{
        left: 0px;
        visibility: visible;
        opacity: 1;
    }
    ._bottom-items{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-bottom:30px;
        ._grid{
            flex-wrap: wrap;
            justify-content: center;
            ._contact-block{
                width: 100%;
                text-align: center;
                ._logo-2{
                    margin: 0 auto;
                }
            }
        }
    }
    @media(min-width: $laptop){
        display: none;
    }
}

._page-content{
    margin-top: -120px;
}

._footer{
    background-color: $black;
    padding-top: 100px;
    
    ._row{
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 30px;
        width: 100%;
    }
    ._row-2{
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 30px;
    }
    ._footer-list{
        padding-left: 0;
        list-style: none;
        margin: 0;
        width: 150px;
        max-width: 40%;
    }
    ._footer-link{
        white-space: nowrap;
    }
    ._logo-3{
        margin: 0 auto;
    }
    ._copyright{
        text-align: center;
        padding: 15px;
        margin: 20px 0 0 0;
        border-top: 1px solid $dark;
    }
    ._form{
        padding: 15px 0;
        ._button{
            margin-right: 0;
            margin-left: auto;
        }
    }
    @media(min-width: $tablet){
        ._row{
            flex-wrap: nowrap;
            column-gap: 60px;
        }
        ._logo-3{
            margin-left: 0;
        }
        ._newsletter-item{
            width: 1000px!important;
        }
    }
}