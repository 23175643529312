body{
    font-family: $font;
    background-color: $dark;
    color: #fff;
}

._heading-1{
    font-family: $font2;
    font-weight: 300;
    text-shadow: $textShadow;
    font-size: 47px;
    line-height: 54px;
    margin: 0;
    @media(min-width: 564px){
        font-size: 75px;
        line-height: 85px;
    }
    @media(min-width: $tablet){
        font-size: 80px;
        line-height: 90px;
    }
    @media(min-width: $laptop){
        font-size: 100px;
        line-height: 126px;
    }
}

._heading-2{
    font-weight: 400;
    text-shadow: $textShadow;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    @media(min-width: 564px){
        font-size: 30px;
        line-height: 34px;
    }
    @media(min-width: $tablet){
        font-size: 32px;
        line-height: 38px;
    }
    @media(min-width: $laptop){
        font-size: 40px;
        line-height: 50px;
    }
}

._heading-3{
    font-family: $font2;
    font-weight: 300;
    font-size: 47px;
    line-height: 54px;
    margin-top: 10px;
    margin-bottom: 10px;
    @media(min-width: $tablet){
        font-size: 60px;
        line-height: 70px;
    }
    @media(min-width: $laptop){
        font-size: 60px;
        line-height: 70px;
    }
}

._heading-4{
    font-family: $font2;
    font-weight: 300;
    font-size: 30px;
    line-height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    @media(min-width: $tablet){
        font-size: 35px;
        line-height: 50px;
    }
    @media(min-width: $laptop){
        font-size: 40px;
        line-height: 60px;
    }
}

._heading-5{
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
    @media(min-width: $tablet){
        font-size: 20px;
        line-height: 24px;
    }
    @media(min-width: $laptop){
        font-size: 20px;
        line-height: 24px;
    }
}

._heading-6{
    font-family: $font;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    @media(min-width: $tablet){
        font-size: 25px;
        line-height: 34px;
    }
    @media(min-width: $laptop){
        font-size: 30px;
        line-height: 40px;
    }
}

._par-big{
    font-size: 20px;
}

._link-text{
    color: $gold;
    font-weight: 300;
    font-size: 14px;
}
._light-text{
    font-size: 14px;
    font-weight: 300;
    color: $grey;
}

._button-group{
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
    @media(min-width: $tablet){
        column-gap: 20px;
        row-gap: 20px;
    }
    @media(min-width: $laptop){
        column-gap: 30px;
        row-gap: 30px;
    }
}

._button{
    min-height: 40px;
    padding: 10px;
    border: 1px solid $gold;
    background-color: $gold;
    font-family: $font3;
    font-size: 14px;
    color: $dark;
    min-width: 124px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    &._button-2{
        background-color: $dark;
        color: #fff;
    }
    &._button-3{
        background-color: #fff;
    }
}

._nav-list{
    padding-left: 0;
    display: flex;
    align-items: center;
    margin: 0;
    height: 100%;
    ._nav-item{
        list-style: none;
        padding: 0 17px;
        height: 100%;
        ._nav-link{
            height: 100%;
            color: #fff;
            display: block;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0.42px;
            text-shadow: $textShadow;
            display: flex;
            align-items: center;
        }
        @media(min-width: 1100px){
            padding: 0 30px;
        }
    }
}

._logo{
    width: 170px;
    display: block; 
    img{
        width: 100%;
    }
}

._logo-2{
    width: 170px;
    display: block; 
    img{
        width: 100%;
    }
}

._logo-3{
    width: 270px;
    display: block; 
    img{
        width: 100%;
    }
}

._contact-block{
    display: inline-block;
    text-align: center;
    a{
        text-shadow:  $textShadow;
    }
}

._scroll-down-button{
    display: inline-block;
    text-align: center;
    background-color: transparent;
    border: none;
    color: #fff;
    text-shadow:  $textShadow;
    cursor: pointer;
    span{
        font-weight: 10px;
        display: block;
        text-shadow:  $textShadow;
    }
    img{
        width: 12px;
        margin-top: 10px;
        @include transition(.25s, .25s);
        position: relative;
        top: 0;
    }
    &:hover{
        img{
            top: 5px;
        }
    }
}

._form{
    padding: 30px 0;
}

._input-row, ._form-row{
    @media(min-width: $tablet){
        column-gap: 20px;
        row-gap: 20px;
        display: flex;
        align-items: center;
    }
}

._input-group, ._form-group {
    position: relative;
    padding: 15px 0;
    width: 100%;
    label{
        display: block;
        font-size: 14px;
        font-weight: 300;
        color: $grey;
        margin-bottom: 7px;
    }
}

._input, ._form-element{
    width: 100%;
    display: block;
    background-color: #fff;
    color: $dark;
    min-height: 40px;
    border: 1px solid $borderColor;
    
}

._hamburger-icon{
    height: 40px;
    width: 40px;
    border: none;
    background-color: transparent;
    position: relative;
    cursor: pointer;
    span{
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $gold;
        left: 0;
        @include transition;
        &:nth-of-type(1){
            top: 14px;
        }
        &:nth-of-type(2){
            bottom: 14px;
        }
    }
    &._open{
        span{
            &:nth-of-type(1){
                top: 19px;
                transform: rotate(45deg);
            }
            &:nth-of-type(2){
                bottom: 19px;
                transform: rotate(-45deg);
            }
        }
    }
}

._section-general{
    padding: 60px 0;
    background-position: center;
    background-size: cover;
    p{
        margin-top: 25px;
        margin-bottom: 25px;
    }
    h2{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    h3{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    p, h2, h3, h4, h5, a{
        max-width: 970px;
        margin-left: auto;
        margin-right: auto;
    }
    
    @media(min-width: $laptop){
        padding: 100px 0;
    }
}

._section-split-column{
    ._split-grid{
        display: flex;
        flex-wrap: wrap;
        ._split-column{
            width: 100%;
            background-size: cover;
            background-position: center;
            &:nth-of-type(1){
                height: 90vw;
            }
        }
        ._panel{
            margin: 0 auto;
            width: calc(100% - 40px);
            max-width: 560px;
            overflow-y: auto;
            padding: 60px 0;
            
            @media(min-width: $tablet){
                width: calc(100% - 60px);
                max-height: 80%;
                padding: 15px 0;
            }
            @media(min-width: $laptop){
                width: calc(100% - 80px);
            }
        }
    }
    img{
        max-width: 325px;
        width: auto;
    }
    ._heading-3{
        margin-left: 13px;
        margin-top: 0;
    }
    ._button{
        margin-top: 15px;
        display: inline-block;
    }
    @media(min-width: $tablet){
        ._split-grid{
            ._split-column{
                width: 50%;
                height: 100vh;
                display: flex;
                align-items: center;
                &:nth-of-type(1){
                    height: 100vh;
                }
            }
            &:nth-child(even){
                ._split-column{
                    &:nth-of-type(1){
                        order: 2;
                    }
                }
            }
        }
    }
}

._section-top-no-banner{
    margin-top: 160px;
    text-align: center;
}

._section-top-banner{
    height: 400px;
    position: relative;
    text-align: center;
    background-position: center;
    background-size: cover;
    // ._heading-3{
    //     text-shadow: $textShadow;
    // }
    ._panel{
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        padding: 30px 0;
        z-index: 1;
    }
    ._overlay{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.3);
        z-index: 0;
    }
}

._vl{
    height: 18px;
    width: 1px;
    background-color: $gold;
}

._unordered-list{
    padding-left: 30px;
    list-style: none;
    font-weight: 400;
    line-height: 30px;
    li{
        position: relative;
        &:before{
            content: "";
            position: absolute;
            top: 14px;
            left: -28px;
            width: 4px;
            height: 4px;
            border-radius: 100px;
            background-color: #fff;
        }
    }
}

._masonary-grid{
    column-count: 1;
    column-gap: 10px;
    img {
        max-width: 100%;
        display: block;
    }

    ._mg_col{
        margin: 0 0 40px 0;
        display: grid;
        grid-template-rows: 1fr auto;
        break-inside: avoid;
        color: black;
        text-decoration: none;
    }
    
    figure {
        background-position: center;
        background-size: cover;
        @media (min-width: $laptop){
            &._animation-image-swith{
                img{
                    @include transition;
                }
                &:hover{
                    img{
                        opacity: 0;
                    }
                }
            }
        }
    }
    
    figure > img {
        grid-row: 1 / -1;
        grid-column: 1;
        opacity: 1;
        @include transition;
    }
    
    
    @media (min-width: $tablet){
        column-count: 2;
        column-gap: 20px;
    }
    @media (min-width: $laptop){
        column-count: 3;
        column-gap: 30px;
    }
}

._iframe-continer{
    position: relative;
    padding-bottom: 400px;
    height: 0;
    background-color: #fff;
    iframe{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}

._section-form{
    padding-bottom: 60px;
    ._panel{
        max-width: 800px;
        margin: 0 auto;
        form{
            ._button{
                min-width: 170px;
                margin: 0 auto;
                display: block;
            }
        }
    }
    @media(min-width: $laptop){
        padding-bottom: 100px;
    }
}

._section-enquire{
    margin-top: -200px;
    padding-bottom: 60px;
    position: relative;
    z-index: 1; 
    ._heading-3{
        margin-bottom: 30px;
        text-align: center;
    }    
    ._panel{
        background-color: #fff;
        max-width: 970px;
        margin: 0 auto;
        color: $dark;
        padding: 30px 0;
        @media(min-width: $tablet){
            padding: 40px 0;
        }
        @media(min-width: $laptop){
            padding: 50px 0;
        }
    }
    ._headline-row{
        display: flex;
        align-items: center;
        column-gap: 30px;
        padding: 0 30px;
        h2{
            margin: 0;
        }
        p{
            margin: 0;
        }
        @media(min-width: $tablet){
            padding: 0 40px;
        }
        @media(min-width: $laptop){
            padding: 0 50px;
        }
    }
    ._errors-final{
        padding: 0 30px;
        color: red;
        margin: 30px 0;
        @media(min-width: $tablet){
            padding: 0 40px;
        }
        @media(min-width: $laptop){
            padding: 0 50px;
        }
    }
    label{
        color: $dark;
        font-weight: 400;
    }
    h3{
        font-weight: 600;
        margin: 30px 0 10px 0;
    }
    ._form-slide{
        padding: 0 30px;
        @media(min-width: $tablet){
            padding: 0 40px;
        }
        @media(min-width: $laptop){
            padding: 0 50px;
        }
    }
    ._form-group{
        @media(min-width:$tablet){
            width: 31.5%;
        }
    }
    ._button{
        margin-top: 30px;
        display: block;
    }
    ._button-row{
        justify-content: center;
    }
    .slick-arrow{
        display: none!important;
    }
    ._error-message{
        color: red;
        position: absolute;
        font-weight: 600;
        font-style: italic;
    }
    ._error{
        input{
            border: 1px solid red;
        }
    }
    @media(min-width: $laptop){
        padding-bottom: 60px;
    }
}

._error-message{
    color: red;
}

._checkbox-row{
    display: flex;
    align-items: center;
    ._form-element{
        width: 40px;
    }
}

._form-group-checkbox{
    margin: 30px 0 30px 0;
}

._form-group-textarea{
    width: 100%;
}

._submit-button{
    @include transition(0.5s);
    position: relative;
    top: 0;
    left: 0;
    @media (min-width: $laptop){
        &:hover{
            opacity: 0.9;
        }
    }
    &._sending{
        left: 100vw;
    }
}