*, *:before, *:after {
    box-sizing: border-box;
}

body{
    margin: 0;
}

p{
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    margin-top: 25px;
    margin-bottom: 25px;
}

a{
    text-decoration: none;
    color: inherit;
}