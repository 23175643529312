@mixin transition($speed: 0.25s, $delay: 0s){
    transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    transition: $speed;
    -o-transition: $speed;
    -moz-transition: $speed;
    -webkit-transition: $speed;
    transition-delay: $delay;
    -moz-transition-delay: $delay;
    -o-transition-delay: $delay;
    -webkit-transition-delay: $delay;
}

@mixin boxShadow {
    box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.2);
}