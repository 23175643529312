._section-hero-banner{
    height: 100vh;
    min-height: 600px;
    position: relative;
    background-position: center;
    background-size: cover;
    ._heading-main{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        z-index: 1;
        ._heading-2{
            margin: 0 0 15px 0;
            @media(min-width: 564px){
                margin: 0;
            }
        }
        ._heading-1{
            margin: 0 0 30px 0;
            position: relative;
            @media(min-width: 564px){
                left: 20px;
                margin-top: 0;
            }
        }
    }
    ._bottom-items{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding-bottom: 30px;
        z-index: 1;
        display:none;
        ._grid{
            justify-content: space-between;
            align-items: flex-end;
        }
        @media(min-width:$tablet){
            display: block;
        }
    }
    ._scroll-down-button{
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
    ._overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(35,43,47);
        background: linear-gradient(0deg, rgba(35,43,47,1) 3%, rgba(35,43,47,0) 32%, rgba(35,43,47,0) 100%);    
        z-index: 0;
    }
}

._section-home-history{
    padding-top: 60px;
    text-align: center;
}

._section-enquire-now{
    position: relative;
    text-align: center;
    ._overlay{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        z-index: 0;
    }
    ._column{
        z-index: 1;
        position: relative;
    }
    ._button{
        margin-top: 15px;
        display: inline-block;
    }
}

._section-facilities{
    padding: 30px 0;
    text-align: center;
    ._panel{
        max-width: 970px;
        margin: 0 auto;
    }
}

._nav-facilities{
    width: 700px;
    max-width: 100%;
    margin: 35px auto 0 auto;
    border: 1px solid $black;
    overflow-x: auto;
    ul{
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        li{
            a, button{
                font-weight: 300;
                font-size: 8px;
                display: block;
                padding: 20px 10px;
                background-color: transparent;
                border: none;
                color: #fff;
                cursor: pointer;
                margin: 0 auto;
                white-space: nowrap;
                @media(min-width: $tablet){
                    font-size: 14px;
                    padding: 20px 20px;
                }
            }
        }
    }
}

._venue-item{
    padding: 30px 0;
    ._content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        align-items: baseline;
        padding: 30px 0;
    }
    ._venue-preview-image{
        img{
            width: 100%;
        }
    }
    .slick-dots{
        bottom: 15px;
        li button::before{
            font-size: 16px;
            -webkit-text-stroke: 1px $gold;
            opacity: 1;
            color: transparent;
        }
        li.slick-active button::before{
            opacity: 1;
            color: $black;
        }
        li{
            margin: 0;
        }
    }
    ._heading-6{
        margin: 0 0 15px 0;
    }
    ._description{
        
        @media(min-width: $tablet){
            max-height: 200px;
            overflow-y: auto;
            width: 100%;
        }
    }
    @media(min-width: $tablet){
        ._content{
            padding: 0;
        }
    }
    
    
    ._button{
        margin-top: 30px;
    }
}

._section-facility{
    padding: 30px 0;
    h2{
        margin-top: 0;
    }
    ._panel{
        max-width: 970px;
        margin: 0 auto;
    }
}

._section-gallery{
    padding: 60px 0;
    @media(min-width: $laptop){
        padding: 100px 0;
    }
}

._section-location{
    padding-bottom: 60px;
    ._panel{
        max-width: 970px;
        margin: 0 auto;
        position: relative;
        ._button{
            margin-top: 30px;
            display: inline-block;
        }
    }
    ._heading-3{
        margin-top: 0;
        margin-bottom: 30px;
    }
    @media(min-width: $tablet){
        padding-bottom: 100px;
        ._row{   
            display: flex;     
            ._item{
                width: 50%;
            }
        }
    }        
}

._form-contact{
    max-width: 570px;
    margin: 0 auto;
}