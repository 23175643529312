$font: 'Noto Sans', sans-serif;
$font2: 'Snell Roundhand';
$font3: 'Cinzel', serif;

$gold: #DFBD7E;
$dark: #232B2F;
$black: #1C2022;
$grey: #C1C1C1;
$textShadow:  0px 1px 3px rgba(0, 0, 0, 0.65);
$textShadowGold:  0px 1px 3px #fff;
$borderColor: #D6D6D6;

$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;